html {
  //-----------------------------------------------Variable used for project styles------------------------------------
  --myBackgroundColor: #22242626;
  --myPrimaryFont: Roboto;
  --myPrimaryColor: #6b50ff;
  --buttonTextColot: #ffffff;
  --borderRadius: 20px;

  @media screen and (min-width: 1200px) {
    h1 {
      font-size: 1.6vw !important;
    }

    h2 {
      font-size: 1.4vw !important;
    }

    h3 {
      font-size: 1.2vw !important;
    }

    h4 {
      font-size: 1vw !important;
    }
  }

  //-----------------------------------------------Commons------------------------------------
  body {
    background-color: #ffffff;
    
    @media screen and (min-width: 1200px) {
      height: auto;
      background-color: var(--myBackgroundColor);
      padding-bottom: 1px !important;
    }
  }

  *:not(i) {
    font-family: var(--myPrimaryFont) !important;
  }

  .primaryColor {
    color: var(--myPrimaryColor) !important;
  }
  
  button {
    color: var(--buttonTextColor) !important;
    background-color: var(--myPrimaryColor) !important;
  }

  .label {
    color: var(--myPrimaryColor) !important;
    border-color: var(--myPrimaryColor) !important;
  }

  i {
    color: var(--myPrimaryColor);
  }

  a {
    text-decoration: underline;
  }

  ul {
    margin: 0;
  }

  //design style for tablet and desktop
  .ui.container {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    margin: 0 auto;
    padding: 1rem;
    text-align: center;

    @media screen and (min-width: 1200px) {
      width: 70%;
      border: 1px solid #d3d3d3;
      border-radius: var(--borderRadius);
      margin: 70px auto;
      padding: 2rem;
    }
  }

  //-----------------------------------------------Upload buttons style------------------------------------
  .upload-button {
    width: 130px;
    background-color: var(--myBackgroundColor);
    border-radius: var(--borderRadius);
    padding: 1.25rem 0.25rem;
    cursor: pointer;
    text-align: center;

    .upload-button-icon {
      margin: 0.5rem 0;
      color: var(--myPrimaryColor);
    }

    .upload-button-label {
      margin: 0.25rem;
      color: var(--myPrimaryColor);
    }
  }

  .upload-button:hover {
    background-color: var(--myPrimaryColor);

    .upload-button-icon {
      color: var(--buttonTextColot);
    }

    .upload-button-label {
      color: var(--buttonTextColot);
    }
  }

  //-----------------------------------------------FilePond style------------------------------------
  .filepond-wrapper {
    .filepond--drop-label {
      min-height: 65px;
      border-radius: 8px;
      font-family: inherit;
      font-size: 18px;
    }
    
    span.filepond--label-action {
      text-decoration: none;
    }

    //Hide upload/retry button from the filepond control to force user using the "Submit" button.
    .filepond--file {
      .filepond--action-process-item,
      .filepond--action-retry-item-processing {
        display: none;
      }
    }
  }
}
